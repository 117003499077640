<template>
  <page>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="退款单号" prop="orderRefundId">
          <el-input placeholder="退款单号" v-model="params.orderRefundId" />
        </el-form-item>

        <el-form-item label="支付单号" prop="oriOrderId">
          <el-input placeholder="支付单号" v-model="params.oriOrderId" />
        </el-form-item>

        <el-form-item label="门店名称" prop="storesName">
          <el-input placeholder="门店名称" v-model="params.storesName" />
        </el-form-item>

        <el-form-item label="退款时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="退款方式" prop="payType">
          <el-select v-model="params.payType" clearable>
            <el-option
              v-for="item in payTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button v-auth="'refund:down'" @click="exportOrder" type="primary">
        下载报表信息
      </el-button>
    </template>

    <el-table
      v-loading="loading"
      :data="tableData"
      border
      height="100%"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="id"
        label="退款单号"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="oriOrderId"
        label="支付单号"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="storesName"
        label="退款门店"
      ></el-table-column>

      <el-table-column show-overflow-tooltip label="退款金额">
        <template slot-scope="{ row }">
          <span>￥{{ $utils.formatNum(row.payPrice) }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="退款类型">
        <template slot-scope="{ row }">
          <span>{{ row.refundType == 1 ? '整单退款' : '部分退款' }}</span>
        </template></el-table-column
      >
      <el-table-column show-overflow-tooltip prop="payTypeStr" label="退款方式">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="orderTime"
        label="退款时间"
      ></el-table-column>
    </el-table>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      tableData: [],
      payTypeObj: {
        0: '扫码退款',
        1: '工行卡退款',
        2: '线下退款',
        3: '余额退款'
      },
      payTypeList: [
        {
          label: '扫码退款',
          value: 0
        },
        {
          label: '工行卡退款',
          value: 1
        },
        {
          label: '线下退款',
          value: 2
        },
        {
          label: '余额退款',
          value: 3
        }
      ],
      params: {
        state: 1,
        date: [],
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async exportOrder() {
      try {
        this.loading = true

        const params = { ...this.params }

        const { limit, page, ...sendData } = params

        await this.$api.reportManagement.exportRefundOrder(sendData)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getData(query) {
      this.loading = true
      let params = { ...this.params }
      if (query) {
        params.page = 1
        params.limit = 20
      }
      if (params.date && params.date.length) {
        params.startTime = this.$utils.dateFormat(params.date[0], 'YYYY-MM-DD')
        params.endTime = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
      } else {
        params.startTime = undefined
        params.endTime = undefined
      }
      const { date, ...sendData } = params
      try {
        const r = await this.$api.reportManagement.getRefundOrderList(sendData)
        const tableData = r.page.list.map(item => {
          item.payTypeStr = this.payTypeObj[+item.payType]

          return item
        })
        this.tableData = tableData
        this.totalCount = r.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
